<template>
  <div class="count-charts" ref="chart" :data-title='title'></div>
</template>

<script>
import * as echarts from "echarts"
export default {
  name: "Echart",
  props: {
    index: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    xdata: {
      type: Array,
      default: () => [],
    },
    ydata: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chart: null,
      colorList2: [
        //设置发货柱子的，柱子实现渐变色
        ["#0282DE", "#3F28D0"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#0282DE", "#3F28D0"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#67E0E3", "#0181DE"],
        ["#0282DE", "#3F28D0"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      let that = this
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption({
        animation: false,
        grid: {
          left: "50",
          right: "60",
        },
        legend: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            restore: {
              show: true,
              title: "還原",
            },
            dataZoom: {
              show: true,
              title: {
                zoom: "縮放",
                back: "縮放回原圖",
              },
            },
            saveAsImage: {
              show: true,
              title: "下載圖片",
            },
            magicType: {
              type: ["bar", "line"],
              title: {
                bar: "柱狀圖",
                line: "折線圖",
              },
            },
          },
        },
        xAxis: {
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          type: "category",
          data: that.xdata, //X轴显示
          axisLabel: {
            color: "#333",
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
            padding: [0, 10, 10, 0],
            color: "#ffffff",
          },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: "#333",
            formatter: `{value}`,
          },
        },
        title: [
          {
            left: "left",
            text: that.title,
            textStyle: {
              color: "#333333",
              fontSize: 16,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            //实现渐变色的柱子
            data: that.ydata, //显示的数据
            type: "bar",
            smooth: true, // 平滑
            symbol: "none",
            lineStyle: {
              color: "#FF5858",
            },
            barMaxWidth: 30, //设置柱子的宽度
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#222",
                    fontSize: 14,
                  },
                },
                color: (params) => {
                  let colorList = that.colorList2 //柱子的颜色是普通的颜色
                  let index = params.dataIndex
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length
                  }
                  // return colorList[index]
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //渐变色使用方法
                    { offset: 0, color: colorList[index][0] },
                    { offset: 1, color: colorList[index][1] },
                  ])
                },
              },
            },
          },
        ],
      })
      let my_chart_dom = this.$refs.chart.getElementsByTagName("canvas")[0]
      let chart_png = my_chart_dom.toDataURL()
      that.$emit("changepng", that.index, chart_png)
    },
  },
}
</script>

<style lang="scss" scoped>
.count-charts {
  position: relative;
  margin: 0 auto;
  width: 1140px;
  height: 500px;
  canvas {
    width: 100%;
    height: 100%;
  }
}
</style>
